<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop filter pl-1"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <div
          v-if="mqShallShowLeftSidebar"
          class="sidebar-header"
        >
          <feather-icon
            icon="XIcon"
            size="20"
            class="close-button"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </div>
        <b-row>
          <b-col
            cols="12"
            class="mb-1"
          />
        </b-row>

        <!-- Status -->
        <div class="status">
          <h6 class="filter-title">
            {{ $t('Status') }}
          </h6>
          <b-form-radio-group
            v-model="filters.unread"
            stacked
            class="radio-group"
          >
            <b-form-radio
              v-for="option in options.unread"
              :key="option.text"
              :value="option.value"
            >
              {{ $t(`${option.text}`) }}
            </b-form-radio>
          </b-form-radio-group>
        </div>

      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormRadioGroup, BLink, BFormCheckboxGroup, BFormCheckbox, BButton, BFormRadio,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import store from '@/store'
import { postStatuses } from '@/mixins/options'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormRadio,
    BLink,
    BCard,
    BFormCheckbox,
    BButton,

    // 3rd Party
    VueSlider,
  },
  directives: {
    Ripple,
  },
  mixins: [postStatuses],
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      options: this.filterOptions,
    }
  },
  // watch: {
  //   '$i18n.locale': function (newVal, oldVal) {
  //     this.fetchPurposes()
  //   },
  // },
  created() {

  },
  methods: {

  },
}
</script>

<style lang="scss">

</style>

<style lang="scss" scoped>
// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';
@import '~@core/scss/base/components/include'; // Components includes
.checkbox-group,
.radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.sidebar-shop .sidebar-header .close-button{
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}
.sidebar-shop.filter.show {
  overflow: auto;
  background-color: $white;
}

.dark-layout .sidebar-shop.filter.show {
  background-color: $theme-dark-card-bg;
}
</style>
